import React from "react";
import "twin.macro";
import { StaticImage } from "gatsby-plugin-image";

import { Button, Link } from "../index";
import scrollTo from "gatsby-plugin-smoothscroll";

export interface navButtonProps {
  label: string;
  to?: string | undefined;
  sublabel?: string;
}

export interface subNavLinks {
  label: string;
  to: string;
  sublabel?: string;
}

export interface navItemProps extends navButtonProps {
  subnavs?: subNavLinks[];
}

interface mobileNavProps {
  open: boolean;
  label: string;
  subnavs: subNavLinks[];
}

interface names {
  node: {
    name: string;
    secondaryName: string;
  };
}

const Navbar: React.FC = () => {
  return (
    <header tw="w-full shadow-lg sticky z-30 top-0 border-none bg-hm-light">
      <div tw="w-full sticky z-30 top-0 left-0 max-w-screen-md md:max-w-screen-lg lg:max-w-screen-xl mx-auto">
        <div tw="flex justify-between items-center px-3 py-1 sm:py-3 sm:px-6 md:px-3 md:py-6 lg:px-2 md:space-x-5 lg:space-x-10">
          <Link to="/">
            <span tw="sr-only">Home</span>
            <StaticImage
              src="../../images/logo_circle.png"
              alt="Hobgob and Mulch Logo"
              placeholder="blurred"
              layout="constrained"
              loading="eager"
              tw="w-10 lg:w-16"
            />
          </Link>
          <h2 tw="hidden lg:block text-2xl font-ringbearer">
            {"The Tales Of Hobgob & Mulch"}
          </h2>
          <div>
            <Button
              text="episodes"
              colour="dark"
              size="thin"
              onClick={() => scrollTo("#episodes")}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
