import React from "react";
import "twin.macro";
import { SEO } from "./index";

declare interface pageLayoutProps {
  title: string;
  subtitle?: string;
  intro?: string;
  content?: string;
  mainStyles?: TwStyle;
  flourish?: number;
}

const PageLayout: React.FC<pageLayoutProps> = ({
  title,
  subtitle,
  children,
  mainStyles,
}) => {
  return (
    <>
      <SEO title={title} />
      <main css={[mainStyles]}>
        <section>
          <div tw="bg-gradient-to-r from-denim-800 to-denim-800 via-denim-900 text-white">
            <div tw="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
              <h1 tw="font-extrabold text-5xl text-white tracking-tight">
                {title}
              </h1>
              {!!subtitle && (
                <p tw="mt-3 text-denim-100 text-xl sm:mt-4">{subtitle}</p>
              )}
            </div>
          </div>
        </section>

        {children}
      </main>
    </>
  );
};

export default PageLayout;
