import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import "twin.macro";
import { Link } from ".";

export const makeSlug = (a: string) => {
  const b = a.split("/");
  const c = b[b.length - 1];
  const d = c.substring(c.indexOf("-") + 1).split(".")[0];
  return d;
};

const EpisodeCard = (props) => {
  const {
    title,
    itunes: { episode, season },
    enclosure: { url },
    content: { encoded },
  } = props;

  return (
    <Link to={`/episodes/${makeSlug(url)}`}>
      <div tw="container mx-auto my-5 font-ringbearer letter-spacing[0.05em]">
        <div tw="relative rounded-lg flex flex-col md:flex-row items-center md:shadow-xl mx-2">
          <div tw="z-0 order-1 md:order-2 relative w-full md:w-2/5 h-80 md:h-full overflow-hidden rounded-lg md:rounded-none md:rounded-r-lg">
            <StaticImage src="../../images/hm_banner.jpeg" alt={title} />
            <div tw="md:hidden absolute inset-0 h-full p-6 pb-6 flex flex-col-reverse justify-start items-start bg-gradient-to-b from-transparent via-transparent to-gray-900">
              <h3 tw="w-full font-bold text-2xl text-white leading-tight my-4">
                {title}
              </h3>
              <h4 tw="w-full text-xl text-gray-100 leading-tight">
                {`Volume ${season}, Chapter ${episode}`}
              </h4>
            </div>
            <svg
              tw="hidden md:block absolute inset-y-0 h-full w-24 fill-current text-white -ml-12"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>
          </div>

          <div tw="z-10 order-2 md:order-1 w-full h-full md:w-3/5 flex items-center -mt-6 md:mt-0">
            <div tw="py-8 px-4 md:pr-16 md:pl-14 md:py-12 mx-2 md:mx-0 h-full bg-white rounded-lg md:rounded-none md:rounded-l-lg shadow-xl md:shadow-none">
              <h4 tw="hidden md:block text-xl text-gray-400 my-4">{`Volume ${season}, Chapter ${episode}`}</h4>
              <h3 tw="hidden md:block font-bold text-2xl text-gray-700 my-4">
                {title}
              </h3>
              <div
                tw="prose my-4"
                dangerouslySetInnerHTML={{
                  __html: trimEnding(encoded),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default EpisodeCard;

function trimEnding(str: string) {
  const endingStringToRemove =
    "All stories written and narrated by George Prince.";
  const a = str.replace(endingStringToRemove, "");
  return a.replace(/^(\s*<br( \/)?>)*|(<br( \/)?>\s*)*$/gm, "");
}
