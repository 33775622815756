import React from "react";
import "twin.macro";
import { Link as GatsbyLink } from "gatsby";

export default function Link({ children, to, ...rest }) {
  return (
    <GatsbyLink tw="text-decoration[none]" {...rest} to={to}>
      {children}
    </GatsbyLink>
  );
}
