import React, { ButtonHTMLAttributes } from "react";
import tw, { TwStyle } from "twin.macro";

import { Link } from "gatsby";

import { toTitleCase } from "../../utils/text-utils";
import { FaCircleNotch } from "react-icons/fa";

declare interface buttonProps {
  text: string;
  to?: string;
  href?: string;
  colour?:
    | "default"
    | "primary"
    | "dark"
    | "lightest"
    | "secondary"
    | "secondary-dark";
  size?: "normal" | "thin";
  full?: boolean;
  loading?: boolean;
  onClick?: () => void;
  customStyles?: TwStyle;
}

const Button: React.FC<buttonProps> = ({
  to,
  href,
  colour = "default",
  size = "normal",
  customStyles,
  full,
  text,
  loading,
  ...rest
}) => {
  const label = text; //toTitleCase(text);
  const css = [
    tw`rounded-sm shadow-sm border border-transparent py-3 text-base text-center focus:outline-none no-underline! whitespace-nowrap font-ringbearer letter-spacing[0.05em]`,
    // colours
    colour === "default" && tw`text-gray-900! bg-white hover:bg-gray-100`,
    colour === "primary" && tw`text-gray-900! bg-hm-dark hover:bg-hm-base`,
    colour === "dark" && tw`text-white! bg-hm-darkest hover:bg-hm-dark`,
    colour === "lightest" && tw`text-white! bg-denim-600 hover:bg-denim-700`,
    colour === "secondary" &&
      tw`text-denim-700! bg-denim-50 hover:bg-denim-200 `,
    colour === "secondary-dark" &&
      tw`text-blue-700! bg-blue-100 hover:bg-blue-200`,
    // sizes
    size === "normal" && tw`px-8`,
    size === "thin" && tw`px-4`,
    full && tw`w-full block`,
    // extra classes
    customStyles,
  ];
  if (to) {
    return (
      <Link to={to} css={css} {...rest}>
        {label}
      </Link>
    );
  }
  if (href) {
    return (
      <a
        href={href}
        css={css}
        {...rest}
        target="_blank"
        rel="noopener noreferrer"
      >
        {label}
      </a>
    );
  }
  return (
    <button
      css={[...css, loading && tw`flex px-3 justify-between items-center`]}
      {...rest}
    >
      {loading && (
        <span tw="mr-1">
          <FaCircleNotch tw="animate-spin" />
        </span>
      )}
      {label}
    </button>
  );
};

export default Button;
